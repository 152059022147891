export enum ACTUATION_TYPE {
  APPLICANT = 'APPLICANT',
  BUYER = 'BUYER',
  CREDITOR = 'CREDITOR',
  DEBTOR = 'DEBTOR',
  RAISER = 'RAISER',
  REPRESENTATIVE = 'REPRESENTATIVE',
  SELLER = 'SELLER',
}

export enum PERSON_TYPE {
  CHILEAN = 'CHILEAN',
  FOREIGNER_WITH_RUT = 'FOREIGNER_WITH_RUT',
  FOREIGNER = 'FOREIGNER',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export enum GENDER {
  FEMALE = 'F',
  MALE = 'M',
}

export enum NATURE {
  PORTABILITY = 'PORTABILITY',
  REFINANCING = 'REFINANCING',
  SALE = 'SALE',
}

export enum DOCUMENT_TYPE {
  ANNEXED = 'ANNEXED',
  DEED = 'DEED',
}

export enum SIGNATURE_STATUS {
  SIGNED = 'FIRMADO',
  WAITING_FOR_SIGNATURE = 'ESPERA DE FIRMA',
  NO_SIGNATURE = 'SIN FIRMA',
}

export const ACTUATION_TYPE_LABEL: Record<ACTUATION_TYPE, string> = {
  [ACTUATION_TYPE.APPLICANT]: 'Requirente',
  [ACTUATION_TYPE.BUYER]: 'Comprador',
  [ACTUATION_TYPE.CREDITOR]: 'Acreedor',
  [ACTUATION_TYPE.DEBTOR]: 'Deudor',
  [ACTUATION_TYPE.RAISER]: 'Alzante',
  [ACTUATION_TYPE.REPRESENTATIVE]: 'Representante',
  [ACTUATION_TYPE.SELLER]: 'Vendedor',
};

export const PERSON_TYPE_LABEL: Record<PERSON_TYPE, string> = {
  [PERSON_TYPE.CHILEAN]: 'Chileno',
  [PERSON_TYPE.FOREIGNER_WITH_RUT]: 'Extranjero con RUT',
  [PERSON_TYPE.FOREIGNER]: 'Extranjero',
  [PERSON_TYPE.LEGAL_ENTITY]: 'Persona jurídica',
};

export const GENDER_LABEL: Record<GENDER, string> = {
  [GENDER.FEMALE]: 'Femenino',
  [GENDER.MALE]: 'Masculino',
};

export const NATURE_LABEL: Record<NATURE, string> = {
  [NATURE.PORTABILITY]: 'Portabilidad',
  [NATURE.REFINANCING]: 'Refinanciamiento',
  [NATURE.SALE]: 'Compraventa',
};

export const DOCUMENT_TYPE_LABEL: Record<DOCUMENT_TYPE, string> = {
  [DOCUMENT_TYPE.ANNEXED]: 'Anexo',
  [DOCUMENT_TYPE.DEED]: 'Escritura',
};
