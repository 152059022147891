import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { PERSON_TYPE } from '../../../../domain/constants/notary';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';
const HUMANS = [
  PERSON_TYPE.CHILEAN,
  PERSON_TYPE.FOREIGNER,
  PERSON_TYPE.FOREIGNER_WITH_RUT,
];

export const appearingValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const { person, personType } = control.getRawValue();
  const errors: { key: string; message: string }[] = [];

  if (HUMANS.includes(personType)) {
    const requiredFields = ['fatherLastName', 'motherLastName', 'gender'];

    requiredFields.forEach((field) => {
      if (!person[field]) {
        errors.push({
          key: field,
          message: `${REQUIRED_MESSAGE} (appearing > person > ${field})`,
        });
      }
    });
  }

  if (!errors.length) {
    return null;
  }

  return errors.reduce(
    (acc, { key, message }) => ({
      ...acc,
      [key]: message,
    }),
    {}
  );
};
