import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

import { BuyerFormGroup } from './types';
import { RutMaskDirective } from '../../../utils/directives/rut-mask.directive';

@Component({
  selector: 'app-create-work-order-buyer-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RutMaskDirective,
  ],
  templateUrl: './create-work-order-buyer-form.component.html',
  styleUrl: './create-work-order-buyer-form.component.scss',
})
export class CreateWorkOrderBuyerFormComponent {
  @Input({ required: true }) buyerFormGroup!: BuyerFormGroup;
}
