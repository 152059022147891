import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

const formErrorsToArray = (
  errors: ValidationErrors | null,
  controlKey: string
) => {
  if (!errors) {
    return [];
  }

  return Object.keys(errors)
    .filter((errorKey) => errors[errorKey] !== null)
    .map((errorKey) => {
      if (errorKey === 'required') {
        return `${REQUIRED_MESSAGE} (${controlKey})`;
      }

      return errors[errorKey];
    });
};

export const getFormErrors = (
  form: AbstractControl,
  controlKey: string = 'root'
) => {
  if (form instanceof FormControl) {
    return formErrorsToArray(form.errors, controlKey);
  }

  if (form instanceof FormArray) {
    const formArrayErrors = formErrorsToArray(form.errors, controlKey);

    form.controls.forEach((control) => {
      const errors = getFormErrors(control, controlKey);

      formArrayErrors.push(...errors);
    });

    return formArrayErrors;
  }

  if (form instanceof FormGroup) {
    const formGroupErrors = formErrorsToArray(form.errors, controlKey);

    Object.keys(form.controls).forEach((key) => {
      const breadcrumbs =
        controlKey === 'root' ? key : `${controlKey} > ${key}`;
      const errors = getFormErrors(form.controls[key], breadcrumbs);

      formGroupErrors.push(...errors);
    });

    return formGroupErrors;
  }

  return [];
};
