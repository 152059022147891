import { Component } from '@angular/core';

import { BaseStateComponent } from '../../../../components/base-state/base-state.component';
import { CreateWorkOrderStateData } from '../../../../domain/types/work-order-states';
import { StateViewAbstractComponent } from '../../../../components/state-view-component/state-view-abstract.component';
import { WORK_ORDER_STATE } from '../../../../domain/constants/work-order-states';

@Component({
  selector: 'app-create-work-order',
  standalone: true,
  imports: [BaseStateComponent],
  templateUrl: './create-work-order.component.html',
  styleUrl: './create-work-order.component.scss',
})
export class CreateWorkOrderComponent extends StateViewAbstractComponent<CreateWorkOrderStateData> {
  static STATE_ID = WORK_ORDER_STATE.CREATE_WORK_ORDER;
}
