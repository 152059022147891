import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { WorkOrdersService } from '../../../services/work-orders.service';
import { WorkOrderDocument } from '../../../domain/types/work-order';

@Component({
  selector: 'app-documents-list-item',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './documents-list-item.component.html',
  styleUrl: './documents-list-item.component.scss',
})
export class DocumentsListItemComponent {
  @Input() document!: WorkOrderDocument;

  constructor(private workOrdersService: WorkOrdersService) {}

  onDownload(): void {
    this.workOrdersService.downloadDocument(
      this.document.file.name,
      this.document.file.url
    );
  }
}
