import { Injectable } from '@angular/core';

import { Session } from './session';

/**
 * Keep roles sync with the backend
 * class RoleGroup
 */
export enum ROLE {
  ADMINISTRATOR = 'ADMINISTRATOR',
}

/**
 * Map values should be the same as the backend
 * class RoleGroup
 */
const roleMap = {
  [ROLE.ADMINISTRATOR]: 'Administrator',
};

export enum PAGE_SECTION {
  WORK_ORDERS_LIST = 'WORK_ORDERS_LIST',
  WORK_ORDER = 'WORK_ORDER',
}

export enum ACTION {
  WORK_ORDERS_UPDATE__ALL = 'WORK_ORDERS_UPDATE__ALL',
}

const sectionViewCapabilities: Record<PAGE_SECTION, ROLE[]> = {
  [PAGE_SECTION.WORK_ORDERS_LIST]: [ROLE.ADMINISTRATOR],
  [PAGE_SECTION.WORK_ORDER]: [ROLE.ADMINISTRATOR],
};

const actionsCapabilities: Record<ACTION, ROLE[]> = {
  [ACTION.WORK_ORDERS_UPDATE__ALL]: [ROLE.ADMINISTRATOR],
};

@Injectable({
  providedIn: 'root',
})
export class UserCapabilities {
  static readonly ROLE = ROLE;
  static readonly PAGE_SECTION = PAGE_SECTION;
  static readonly ACTION = ACTION;

  constructor(private session: Session) {}

  public hasRole(role: ROLE): boolean {
    const user = this.session.getUser();

    if (!user) {
      return false;
    }

    return user.roles.includes(roleMap[role]);
  }

  public hasAnyRole(roles: ROLE[]): boolean {
    return roles.some((role) => this.hasRole(role));
  }

  public hasSectionViewCapability(section: PAGE_SECTION): boolean {
    const allowedRoles = sectionViewCapabilities[section] || [];

    return this.hasAnyRole(allowedRoles);
  }

  public hasActionCapability(action: ACTION): boolean {
    const allowedRoles = actionsCapabilities[action] || [];

    return this.hasAnyRole(allowedRoles);
  }
}
