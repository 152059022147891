import { Component } from '@angular/core';

import { BaseStateComponent } from '../../../../components/base-state/base-state.component';
import { NotaryFormSubmitStateData } from '../../../../domain/types/work-order-states';
import { StateViewAbstractComponent } from '../../../../components/state-view-component/state-view-abstract.component';
import { WORK_ORDER_STATE } from '../../../../domain/constants/work-order-states';

@Component({
  selector: 'app-notary-form-submit',
  standalone: true,
  imports: [BaseStateComponent],
  templateUrl: './notary-form-submit.component.html',
  styleUrl: './notary-form-submit.component.scss',
})
export class NotaryFormSubmitComponent extends StateViewAbstractComponent<NotaryFormSubmitStateData> {
  static STATE_ID = WORK_ORDER_STATE.NOTARY_FORM_SUBMIT;
}
