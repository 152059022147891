<div class="app-filling-notary-form-data app-grid">
  <div class="app-column">
    <mat-card class="app-card">
      <mat-card-header>
        <mat-card-subtitle>
          Agrega comparecientes, documentos y naturaleza
        </mat-card-subtitle>
        <mat-card-title>Preparación</mat-card-title>
      </mat-card-header>
      <mat-card-content class="app-card-content">
        <app-notary-form
          [notaryFormModel]="notaryFormModel"
          [saving]="saving"
          (formChange)="onChange($event)"
        />
        <app-submit-button
          class="submit-button"
          [loading]="saving"
          [disabled]="!notaryFormChanges?.form?.dirty || sending"
          (buttonClick)="onSave()"
        >
          {{ !notaryFormChanges?.form?.dirty ? "Guardado" : "Guardar" }}
        </app-submit-button>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="app-column">
    <mat-card class="app-card">
      <mat-card-header>
        <mat-card-subtitle>Asegurate que todo este bien</mat-card-subtitle>
        <mat-card-title>Enviar a inscribir</mat-card-title>
      </mat-card-header>
      <mat-card-content class="app-card-content">
        <app-notary-form-validation
          [errors]="notaryFormChanges?.form?.errors || []"
        />
        <app-submit-button
          class="send-button"
          [loading]="sending"
          [disabled]="
            saving ||
            !notaryFormChanges?.form?.valid ||
            !!notaryFormChanges?.form?.dirty
          "
          [fullWidth]="true"
          [color]="'secondary'"
          (buttonClick)="onSend()"
        >
          {{
            !notaryFormChanges?.form?.dirty
              ? "Enviar a inscribir"
              : "Guardar antes de enviar"
          }}
        </app-submit-button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
