import { Routes } from '@angular/router';

import { AUTH_HOME_ROUTE, ROUTES } from './constants/routes';
import { authGuard, capabilitiesGuard, unAuthGuard } from './guards';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { UserCapabilities } from './services/auth/user-capabilities';
import { WorkOrderComponent } from './pages/work-order/work-order.component';
import { WorkOrdersComponent } from './pages/work-orders/work-orders.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: AUTH_HOME_ROUTE,
    pathMatch: 'full',
  },
  {
    path: ROUTES.LOGIN,
    component: LoginComponent,
    canActivate: [unAuthGuard],
  },
  {
    path: ROUTES.WORK_ORDERS_LIST,
    component: WorkOrdersComponent,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.WORK_ORDERS_LIST),
    ],
  },
  {
    path: `${ROUTES.WORK_ORDER}/:id`,
    component: WorkOrderComponent,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.WORK_ORDER),
    ],
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];
