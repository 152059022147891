import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { NotaryFormComponent } from '../../../../components/notary-form/notary-form.component';
import { FillingNotaryFormDataStateData } from '../../../../domain/types/work-order-states';
import { WorkOrdersService } from '../../../../services/work-orders.service';
import {
  NotaryDocument,
  NotaryFormModel,
} from '../../../../domain/types/notary';
import { NotaryFormChanges } from '../../../../components/notary-form/types';
import { NotaryFormValidationComponent } from '../../../../components/notary-form-validation/notary-form-validation.component';
import { SubmitButtonComponent } from '../../../../components/submit-button/submit-button.component';
import { WORK_ORDER_STATE } from '../../../../domain/constants/work-order-states';
import { StateViewAbstractComponent } from '../../../../components/state-view-component/state-view-abstract.component';

@Component({
  selector: 'app-filling-notary-form-data',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    NotaryFormComponent,
    NotaryFormValidationComponent,
    SubmitButtonComponent,
  ],
  templateUrl: './filling-notary-form-data.component.html',
  styleUrl: './filling-notary-form-data.component.scss',
})
export class FillingNotaryFormDataComponent
  extends StateViewAbstractComponent<FillingNotaryFormDataStateData>
  implements OnInit
{
  static STATE_ID = WORK_ORDER_STATE.FILLING_NOTARY_FORM_DATA;

  errors: string[] = [];
  notaryFormChanges?: NotaryFormChanges;
  notaryFormModel?: NotaryFormModel;
  saving = false;
  sending = false;

  constructor(private workOrdersService: WorkOrdersService) {
    super();
  }

  ngOnInit(): void {
    const { data } = this.workOrderState;

    this.notaryFormModel = data;
  }

  onChange(notaryFormChanges: NotaryFormChanges) {
    this.notaryFormChanges = notaryFormChanges;
  }

  async onSave() {
    if (!this.notaryFormChanges || this.saving) {
      return;
    }

    this.saving = true;

    const { model, files } = this.notaryFormChanges;
    const modelWithFlags = {
      ...model,
      formReadyToSent: false,
    };

    this.workOrdersService
      .updateStateWithFiles<
        NotaryFormModel,
        NotaryDocument,
        FillingNotaryFormDataStateData
      >(this.workOrder.externalId, modelWithFlags, files)
      .subscribe((response) => {
        this.saving = false;
        this.notaryFormModel = response.data;
      });
  }

  async onSend() {
    if (!this.notaryFormChanges || this.sending) {
      return;
    }

    this.sending = true;

    const { model } = this.notaryFormChanges;
    const modelWithFlags = {
      ...model,
      formReadyToSent: true,
    };

    this.workOrdersService
      .updateStateWithFiles<
        NotaryFormModel,
        NotaryDocument,
        FillingNotaryFormDataStateData
      >(this.workOrder.externalId, modelWithFlags, [])
      .subscribe((response) => {
        this.sending = false;
        this.notaryFormModel = response.data;
        this.updateWorkOrder();
      });
  }
}
