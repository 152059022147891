import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';

import { AddAppearingDialogComponent } from '../add-appearing-dialog/add-appearing-dialog.component';
import { AppearingListFormItemComponent } from '../appearing-list-form-item/appearing-list-form-item.component';
import { NotaryAppearingFormGroup } from '../../types';

@Component({
  selector: 'app-appearing-list-form',
  standalone: true,
  imports: [AppearingListFormItemComponent, MatButtonModule],
  templateUrl: './appearing-list-form.component.html',
  styleUrl: './appearing-list-form.component.scss',
})
export class AppearingListFormComponent {
  @Input() notaryAppearingFormArray!: FormArray<NotaryAppearingFormGroup>;

  constructor(public dialog: MatDialog) {}

  removeAppearingItem(index: number) {
    this.notaryAppearingFormArray.markAsDirty();
    this.notaryAppearingFormArray.removeAt(index);
  }

  openAddAppearingDialog() {
    const dialogRef = this.dialog.open(AddAppearingDialogComponent);

    dialogRef
      .afterClosed()
      .subscribe((notaryAppearingFormGroup: NotaryAppearingFormGroup) => {
        if (notaryAppearingFormGroup) {
          this.notaryAppearingFormArray.markAsDirty();
          this.notaryAppearingFormArray.push(notaryAppearingFormGroup);
        }
      });
  }
}
