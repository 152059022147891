<div class="app-notary-documents-list-form-item mat-elevation-z2">
  <div class="type">
    {{ documentTypeLabel }}
  </div>
  <div class="separator">-</div>
  <div class="name">
    @if (document.file.url) {
    <button class="file-link" (click)="onDownload()">
      {{ document.file.name }}
    </button>
    } @else {
    {{ document.file.name }}
    }
  </div>
  <div class="actions">
    <button mat-icon-button (click)="remove.emit()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
