<div class="app-appearing-list-form">
  <h3 class="title">Comparecientes</h3>
  <div class="content">
    @for (notaryAppearingFormGroup of notaryAppearingFormArray.controls; track
    $index) {
    <app-appearing-list-form-item
      [notaryAppearingFormGroup]="notaryAppearingFormGroup"
      (remove)="removeAppearingItem($index)"
    />
    }
  </div>
  <button
    class="add-button"
    mat-raised-button
    (click)="openAddAppearingDialog()"
  >
    Agregar Compareciente
  </button>
</div>
