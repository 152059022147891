import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CreateWorkOrderBuyerFormComponent } from './create-work-order-buyer-form/create-work-order-buyer-form.component';
import { CreateWorkOrderExecutiveFormComponent } from './create-work-order-executive/create-work-order-executive-form.component';
import { CreateWorkOrderStateData } from '../../domain/types/work-order-states';
import { CustomerSelectComponent } from '../customer-select/customer-select.component';
import { NatureSelectComponent } from '../nature-select/nature-select.component';
import { rutValidator } from '../../utils/validators';
import { SubmitButtonComponent } from '../submit-button/submit-button.component';
import { WorkOrdersService } from '../../services/work-orders.service';

export interface DialogData {
  loading: boolean;
}

@Component({
  selector: 'app-create-work-order-dialog',
  templateUrl: './create-work-order-dialog.component.html',
  styleUrl: './create-work-order-dialog.component.scss',
  standalone: true,
  imports: [
    CreateWorkOrderBuyerFormComponent,
    CreateWorkOrderExecutiveFormComponent,
    CustomerSelectComponent,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    NatureSelectComponent,
    ReactiveFormsModule,
    SubmitButtonComponent,
  ],
})
export class CreateWorkOrderDialogComponent {
  loading = false;
  errorMessage = '';

  workOrderFormGroup = new FormGroup({
    nature: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    customerId: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    buyerData: new FormGroup({
      rut: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, rutValidator],
      }),
      names: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      fatherLastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      motherLastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
    }),
    executive: new FormGroup({
      names: new FormControl<string>('', {
        nonNullable: true,
      }),
      lastname: new FormControl<string>('', {
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.email],
      }),
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateWorkOrderDialogComponent>,
    private workOrdersService: WorkOrdersService
  ) {}

  create() {
    this.errorMessage = '';
    this.loading = true;

    this.workOrdersService
      .createWorkOrder(
        this.workOrderFormGroup.value as CreateWorkOrderStateData
      )
      .subscribe({
        next: (workOrder) => {
          this.dialogRef.close(workOrder);
        },
        error: () => {
          this.loading = false;
          this.errorMessage = 'Error creando orden de trabajo';
        },
      });
  }
}
