import { WorkOrderDocument } from '../../domain/types/work-order';
import { WorkOrderDocumentFormGroup, WorkOrderUnsavedDocument } from './types';

export const formToDocument = <W extends WorkOrderDocument>(
  formGroup: WorkOrderDocumentFormGroup<W>
): WorkOrderUnsavedDocument<W> | undefined => {
  const value = formGroup.getRawValue();

  if (!value._file) {
    return;
  }

  return {
    type: value.type as W['type'],
    file: {
      name: value._file.name,
      url: '',
    },
    _file: value._file,
  } as WorkOrderUnsavedDocument<W>;
};
