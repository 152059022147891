<div class="app-create-work-order-buyer-form">
  <h3 class="title">Comprador</h3>
  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Nombres</mat-label>
      <input matInput [formControl]="buyerFormGroup.controls.names" />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>RUT</mat-label>
      <input appRutMask matInput [formControl]="buyerFormGroup.controls.rut" />
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Apellido paterno</mat-label>
      <input matInput [formControl]="buyerFormGroup.controls.fatherLastName" />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Apellido materno</mat-label>
      <input matInput [formControl]="buyerFormGroup.controls.motherLastName" />
    </mat-form-field>
  </div>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Correo electrónico</mat-label>
    <input matInput [formControl]="buyerFormGroup.controls.email" />
  </mat-form-field>
</div>
