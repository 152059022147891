import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { CustomersService } from '../../services/customers.service';
import { NATURE, NATURE_LABEL } from '../../domain/constants/notary';
import { Customer } from '../../domain/types/customer';

@Component({
  selector: 'app-customer-select',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './customer-select.component.html',
  styleUrl: './customer-select.component.scss',
})
export class CustomerSelectComponent implements OnInit {
  @Input({ required: true }) customerFormControl!: FormControl<string>;

  customerList: Customer[] = [];
  customerOptions: { displayName: string; value: string }[] = [];
  loading = false;

  constructor(private customersService: CustomersService) {}

  ngOnInit(): void {
    this.loadCustomers();
  }

  async loadCustomers() {
    this.customersService.getCustomers().subscribe((customers) => {
      this.customerList = customers;
      this.setCustomerOptions();
      this.loading = false;
    });
  }

  natureOptions = Object.keys(NATURE_LABEL)
    .map((key) => ({
      value: key,
      label: NATURE_LABEL[key as NATURE],
    }))
    .sort((option0, option1) => {
      if (option0.label < option1.label) {
        return -1;
      }
      if (option0.label > option1.label) {
        return 1;
      }
      return 0;
    });

  setCustomerOptions() {
    const options = this.customerList.map((customer) => ({
      displayName: customer.name,
      value: customer.id,
    }));
    this.customerOptions = options;
  }
}
