import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

type RefreshTokenResponse = {
  access: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public refresh(refresh: string): Observable<RefreshTokenResponse> {
    return of({
      access: null,
    });
  }
}
