<div class="app-login-form">
  <mat-card class="card">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Usuario</mat-label>
          <input
            matInput
            type="text"
            name="username"
            [ngModel]="username"
            (ngModelChange)="onUsernameChange($event)"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Clave</mat-label>
          <input
            matInput
            type="password"
            name="password"
            [ngModel]="password"
            (ngModelChange)="onPasswordChange($event)"
            required
          />
        </mat-form-field>

        <app-submit-button
          color="primary"
          type="submit"
          [disabled]="disabled"
          [loading]="loading"
          [fullWidth]="true"
        >
          Entrar
        </app-submit-button>

        @if (message) {
        <p class="mat-caption error-message">{{ message }}</p>
        }
      </form>
    </mat-card-content>
  </mat-card>
</div>
