import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const ACCEPTED_TYPES = '*.*';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent {
  @Output() selectedFilesChanged: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('fileInput') fileInputRef?: ElementRef;

  acceptedTypes = ACCEPTED_TYPES;

  fileChanged(event: Event): void {
    const files = (<HTMLInputElement>event.target).files;
    this.selectedFilesChanged.emit(files?.[0]);
  }
}
