<div class="app-appearing-form">
  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Tipo de actuador</mat-label>
      <mat-select [formControl]="notaryAppearingFormGroup.controls.personType">
        @for (option of personTypeOptions; track $index) {
        <mat-option value="{{ option.value }}"> {{ option.label }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Tipo de actuación</mat-label>
      <mat-select
        [formControl]="notaryAppearingFormGroup.controls.actuationType"
      >
        @for (option of actuationTypesOptions; track $index) {
        <mat-option value="{{ option.value }}"> {{ option.label }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input
        matInput
        [formControl]="notaryAppearingFormGroup.controls.person.controls.name"
      />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>RUT</mat-label>
      <input appRutMask matInput [formControl]="_rut" />
    </mat-form-field>
  </div>

  @if (!isActuatorTypeLegalEntity) {
  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Apellido paterno</mat-label>
      <input
        matInput
        [formControl]="
          notaryAppearingFormGroup.controls.person.controls.fatherLastName
        "
      />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Apellido materno</mat-label>
      <input
        matInput
        [formControl]="
          notaryAppearingFormGroup.controls.person.controls.motherLastName
        "
      />
    </mat-form-field>
  </div>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Género</mat-label>
    <mat-select
      [formControl]="notaryAppearingFormGroup.controls.person.controls.gender"
    >
      @for (option of genderOptions; track $index) {
      <mat-option value="{{ option.value }}"> {{ option.label }} </mat-option>
      }
    </mat-select>
  </mat-form-field>
  }

  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Correo electrónico</mat-label>
    <input
      matInput
      [formControl]="notaryAppearingFormGroup.controls.person.controls.mail"
    />
  </mat-form-field>

  <mat-checkbox
    class="form-field"
    [formControl]="notaryAppearingFormGroup.controls.signatureStatus"
  >
    Solicitar firma
  </mat-checkbox>
  <mat-checkbox
    class="form-field"
    [formControl]="notaryAppearingFormGroup.controls.notify"
  >
    Notificar al correo electrónico
  </mat-checkbox>
</div>
