import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { NotaryAppearingFormGroup } from '../../types';
import { AppearingBadgeComponent } from '../appearing-badge/appearing-badge.component';
import { AppearingFormComponent } from '../appearing-form/appearing-form.component';

@Component({
  selector: 'app-appearing-list-form-item',
  standalone: true,
  imports: [
    MatButtonModule,
    MatExpansionModule,
    AppearingBadgeComponent,
    AppearingFormComponent,
  ],
  templateUrl: './appearing-list-form-item.component.html',
  styleUrl: './appearing-list-form-item.component.scss',
})
export class AppearingListFormItemComponent {
  @Input() notaryAppearingFormGroup!: NotaryAppearingFormGroup;
  @Output() remove: EventEmitter<void> = new EventEmitter();
}
