import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { User } from './types';

export type BackendUser = {
  id: number;
  username: string;
  profile: string;
  customer: number | null;
  sendsToInform: boolean;
  allowsRepeatedRut: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUser(): Observable<User | null> {
    return of({
      id: 1,
      username: 'admin',
      roles: ['Administrator'],
    });
  }
}
