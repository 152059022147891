import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { DOCUMENT_TYPE_LABEL } from '../../../../domain/constants/notary';
import { NotaryFormDocument } from '../../types';
import { WorkOrdersService } from '../../../../services/work-orders.service';

@Component({
  selector: 'app-notary-documents-list-form-item',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './notary-documents-list-item.component.html',
  styleUrl: './notary-documents-list-item.component.scss',
})
export class NotaryDocumentsListItemComponent {
  @Input() document!: NotaryFormDocument;
  @Output() remove: EventEmitter<void> = new EventEmitter();

  constructor(private workOrdersService: WorkOrdersService) {}

  get documentTypeLabel(): string {
    if (!this.document.type) {
      return '';
    }

    return DOCUMENT_TYPE_LABEL[this.document.type];
  }

  onDownload(): void {
    this.workOrdersService.downloadDocument(
      this.document.file.name,
      this.document.file.url
    );
  }
}
