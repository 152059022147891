import { packageVersion } from './package-version';

export const environment = {
  env: 'dev',
  version: packageVersion,
  sentry: {
    dsn: '',
  },
  api: 'https://mortgage-api-dev.legalbot.cl',
};
