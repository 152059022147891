{
  "name": "mortgage-workflow-ui",
  "version": "1.23.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "lint": "ng lint",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:dev": "ng build --configuration=development",
    "build:prod": "ng build --configuration=production",
    "prepare": "husky install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.3.4",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/forms": "^17.3.4",
    "@angular/material": "^17.3.4",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@ngx-formly/core": "^6.3.0",
    "@ngx-formly/material": "^6.3.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "17.2.0",
    "@angular-eslint/eslint-plugin": "17.2.0",
    "@angular-eslint/eslint-plugin-template": "17.2.0",
    "@angular-eslint/schematics": "17.2.0",
    "@angular-eslint/template-parser": "17.2.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@commitlint/cli": "^18.4.4",
    "@commitlint/config-conventional": "^18.4.4",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "6.18.0",
    "@typescript-eslint/parser": "6.18.0",
    "eslint": "^8.56.0",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "semantic-release": "^23.0.0",
    "typescript": "~5.2.2"
  }
}
