<div class="app-create-work-order-executive-form">
  <h3 class="title">Ejecutivo</h3>
  <div class="form-row">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Nombres</mat-label>
      <input matInput [formControl]="executiveFormGroup.controls.names" />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Apellido</mat-label>
      <input matInput [formControl]="executiveFormGroup.controls.lastname" />
    </mat-form-field>
  </div>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Correo electrónico</mat-label>
    <input
      matInput
      [formControl]="executiveFormGroup.controls.email"
      type="email"
    />
  </mat-form-field>
</div>
