export const splitRut = (
  rut: string
): { rutDigits: number; checkDigit: string } => {
  const rutDigits = rut.replace(/[^0-9kK]+/g, '');

  if (rut.length <= 1) {
    return {
      rutDigits: Number(rut),
      checkDigit: '',
    };
  }

  return {
    rutDigits: Number(rutDigits.slice(0, -1)),
    checkDigit: rutDigits.slice(-1),
  };
};

export const joinRut = (
  rutDigits?: number | null,
  checkDigit?: string | null
): string => {
  const rutDigitsString = String(rutDigits || '');
  const checkDigitString = checkDigit || '';

  const digits = rutDigitsString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  if (!rutDigitsString.length || !checkDigitString.length) {
    return `${digits}${checkDigitString}`;
  }

  return `${digits}-${checkDigitString}`;
};

export const formatRut = (value: string): string => {
  const { rutDigits, checkDigit } = splitRut(value);

  return joinRut(rutDigits, checkDigit);
};
