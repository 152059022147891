<mat-form-field class="app-customer-select" appearance="outline">
  <mat-label>Cliente</mat-label>
  <mat-select [formControl]="customerFormControl">
    @for (option of customerOptions; track $index) {
    <mat-option [value]="option.value">
      {{ option.displayName }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
