<div class="app-add-notary-document-dialog">
  <h1 mat-dialog-title>Agregar documento</h1>
  <div mat-dialog-content class="content">
    <app-notary-document-form
      [documentFormGroup]="newNotaryDocumentFormGroup"
    />
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="">Cancelar</button>
    <button mat-button [mat-dialog-close]="newDocument">
      Agregar
    </button>
  </div>
</div>
