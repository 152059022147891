<div class="app-state-component-container">
  @if (!loading && workOrderState) {
  <ng-container
    *ngComponentOutlet="
      component;
      inputs: {
        updateWorkOrder,
        updateWorkOrderState,
        workOrder,
        workOrderState
      }
    "
  />
  } @else {
  <div class="loading"><mat-spinner></mat-spinner></div>
  }
</div>
