import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import {
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_LABEL,
} from '../../../../domain/constants/notary';
import { FileInputComponent } from '../../../file-input/file-input.component';
import { WorkOrderDocumentFormGroup } from '../../../../utils/file-upload/types';
import { NotaryDocument } from '../../../../domain/types/notary';

@Component({
  selector: 'app-notary-document-form',
  standalone: true,
  imports: [
    FileInputComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './notary-document-form.component.html',
  styleUrl: './notary-document-form.component.scss',
})
export class NotaryDocumentFormComponent {
  @Input() documentFormGroup!: WorkOrderDocumentFormGroup<NotaryDocument>;

  documentTypesOptions = Object.keys(DOCUMENT_TYPE_LABEL).map((key) => ({
    value: key,
    label: DOCUMENT_TYPE_LABEL[key as DOCUMENT_TYPE],
  }));

  onFileSelected(_file: File): void {
    if (!_file) {
      return;
    }

    this.documentFormGroup.patchValue({
      _file,
    });
  }
}
