type LastLeafValue = string | number | boolean | null | undefined;
type MiddleLeafValue = Record<
  string,
  MiddleLeafValueRef | MiddleLeafValueRef[] | LastLeafValue
>;
interface MiddleLeafValueRef extends MiddleLeafValue {}
export type LeafValue = MiddleLeafValue | MiddleLeafValue[] | LastLeafValue;

const isLastLeafValue = (value: LeafValue): value is LastLeafValue => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null
  );
};

const isArrayLeafValue = (value: LeafValue): value is MiddleLeafValue[] => {
  return Array.isArray(value);
};

const keyReplaceBuilder = (replacer: (key: string) => string) => {
  const keyRelacer = (value: LeafValue): LeafValue => {
    if (isLastLeafValue(value)) {
      return value;
    }

    if (isArrayLeafValue(value)) {
      return value.map(keyRelacer) as MiddleLeafValue[];
    }

    return Object.keys(value).reduce((acc, key) => {
      const camelKey = replacer(key);

      return {
        ...acc,
        [camelKey]: keyRelacer(value[key]),
      };
    }, {});
  };

  return keyRelacer;
};

export const snakeToCamelKeys = keyReplaceBuilder((key) =>
  key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
);

export const camelToSnakeKeys = keyReplaceBuilder((key) =>
  key.replace(/[A-Z]/g, (g) => `_${g.toLowerCase()}`)
);
