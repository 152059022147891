import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

import { BaseStateComponent } from '../../../../components/base-state/base-state.component';
import { DocumentsListComponent } from '../../../../components/documents-list/documents-list.component';
import { NotaryWorkingOnRequestStateData } from '../../../../domain/types/work-order-states';
import { StateViewAbstractComponent } from '../../../../components/state-view-component/state-view-abstract.component';
import { StatusHistoryComponent } from '../../../../components/status-history/status-history.component';
import { SubmitButtonComponent } from '../../../../components/submit-button/submit-button.component';
import { WORK_ORDER_STATE } from '../../../../domain/constants/work-order-states';
import { WorkOrdersService } from '../../../../services/work-orders.service';

@Component({
  selector: 'app-notary-working-on-request',
  standalone: true,
  imports: [
    BaseStateComponent,
    DocumentsListComponent,
    MatCardModule,
    MatListModule,
    StatusHistoryComponent,
    SubmitButtonComponent,
  ],
  templateUrl: './notary-working-on-request.component.html',
  styleUrl: './notary-working-on-request.component.scss',
})
export class NotaryWorkingOnRequestComponent
  extends StateViewAbstractComponent<NotaryWorkingOnRequestStateData>
  implements OnInit
{
  static STATE_ID = WORK_ORDER_STATE.NOTARY_WORKING_ON_REQUEST;

  updating = false;
  state?: NotaryWorkingOnRequestStateData;

  constructor(private workOrderService: WorkOrdersService) {
    super();
  }

  ngOnInit(): void {
    const { data } = this.workOrderState;

    this.state = data;
  }

  onUpdate() {
    if (this.updating) {
      return;
    }

    this.updating = true;

    this.workOrderService
      .updateOnDemand(this.workOrder!.externalId)
      .subscribe((response) => {
        this.updating = false;
        this.state = response.data as NotaryWorkingOnRequestStateData;
      });
  }
}
