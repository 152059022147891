import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';

import { CreateWorkOrderButtonComponent } from '../../components/create-work-order-button/create-work-order-button.component';
import { WorkOrder } from '../../domain/types/work-order';
import { WorkOrdersService } from '../../services/work-orders.service';

@Component({
  standalone: true,
  imports: [CreateWorkOrderButtonComponent, MatTableModule],
  templateUrl: './work-orders.component.html',
  styleUrl: './work-orders.component.scss',
})
export class WorkOrdersComponent implements OnInit {
  displayedColumns: string[] = [
    'createdAt',
    'documentId',
    'state',
    'updatedAt',
  ];
  workOrders: WorkOrder[] = [];

  constructor(
    private router: Router,
    private workOrdersService: WorkOrdersService
  ) {}

  ngOnInit() {
    this.workOrdersService.getWorkOrders().subscribe((workOrders) => {
      this.workOrders = workOrders;
    });
  }

  navigateToWorkOder(workOrder: WorkOrder) {
    this.router.navigate(['/work-order', workOrder.externalId]);
  }

  onWorkOrderCreated(workOrder: WorkOrder) {
    this.router.navigate(['/work-order', workOrder.externalId]);
  }
}
