import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { NgComponentOutlet } from '@angular/common';
import {
  STEPPER_GLOBAL_OPTIONS,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import { StateComponentContainerComponent } from '../../components/state-component-container/state-component-container.component';
import { WORK_ORDER_STATE } from '../../domain/constants/work-order-states';
import { WorkOrder } from '../../domain/types/work-order';
import { WorkOrdersService } from '../../services/work-orders.service';

import { CreateWorkOrderComponent } from './states/create-work-order/create-work-order.component';
import { FillingNotaryFormDataComponent } from './states/filling-notary-form-data/filling-notary-form-data.component';
import { NotaryFormSubmitComponent } from './states/notary-form-submit/notary-form-submit.component';
import { NotaryWorkingOnRequestComponent } from './states/notary-working-on-request/notary-working-on-request.component';
import { WorkOrderCompletedComponent } from './states/work-order-completed/work-order-completed.component';

enum WORK_ORDER_STATE_STATUS {
  DONE = 'done',
  PENDING = 'pending',
  BLOCKED = 'blocked',
}

type WorkOrderUIState = {
  id: WORK_ORDER_STATE;
  Component: NgComponentOutlet['ngComponentOutlet'];
  label: string;
  status: WORK_ORDER_STATE_STATUS;
};

export const WORK_ORDER_STATES: WorkOrderUIState[] = [
  {
    id: WORK_ORDER_STATE.CREATE_WORK_ORDER,
    Component: CreateWorkOrderComponent,
    label: 'Inicio',
    status: WORK_ORDER_STATE_STATUS.BLOCKED,
  },
  {
    id: WORK_ORDER_STATE.FILLING_NOTARY_FORM_DATA,
    Component: FillingNotaryFormDataComponent,
    label: 'Formulario inscripción',
    status: WORK_ORDER_STATE_STATUS.BLOCKED,
  },
  {
    id: WORK_ORDER_STATE.NOTARY_FORM_SUBMIT,
    Component: NotaryFormSubmitComponent,
    label: 'Envío formulario',
    status: WORK_ORDER_STATE_STATUS.BLOCKED,
  },
  {
    id: WORK_ORDER_STATE.NOTARY_WORKING_ON_REQUEST,
    Component: NotaryWorkingOnRequestComponent,
    label: 'En notaría',
    status: WORK_ORDER_STATE_STATUS.BLOCKED,
  },
  {
    id: WORK_ORDER_STATE.WORK_ORDER_COMPLETED,
    Component: WorkOrderCompletedComponent,
    label: 'Final',
    status: WORK_ORDER_STATE_STATUS.BLOCKED,
  },
];

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    StateComponentContainerComponent,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
  /**
   * We are using ViewEncapsulation.None to override Material's
   * stepper styles.
   */
  encapsulation: ViewEncapsulation.None,
})
export class WorkOrderComponent implements OnInit {
  workOrder!: WorkOrder;
  loading = false;

  selectedStateIndex: number = 0;
  states: WorkOrderUIState[] = [];

  constructor(
    private route: ActivatedRoute,
    private workOrdersService: WorkOrdersService
  ) {}

  ngOnInit(): void {
    this.updateWorkOrder();
  }

  updateWorkOrder() {
    if (this.loading) {
      return;
    }

    const workOrderDocumentId = this.route.snapshot.params['id'];
    this.loading = true;

    this.workOrdersService
      .getWorkOrderById(workOrderDocumentId)
      .subscribe((workOrder) => {
        this.initWorkOrderAndStates(workOrder);
        this.loading = false;
      });
  }

  initWorkOrderAndStates(workOrder: WorkOrder) {
    this.workOrder = workOrder;

    const currentStateIndex = WORK_ORDER_STATES.findIndex(
      (state) => state.id === workOrder.state
    );

    const states = WORK_ORDER_STATES.map((state, index) => {
      let status;
      if (index < currentStateIndex) {
        status = WORK_ORDER_STATE_STATUS.DONE;
      } else if (index === currentStateIndex) {
        status = WORK_ORDER_STATE_STATUS.PENDING;
      } else {
        status = WORK_ORDER_STATE_STATUS.BLOCKED;
      }

      return {
        ...state,
        status,
      };
    });

    this.states = states;
    this.selectedStateIndex = currentStateIndex;
  }

  stepSelected(event: StepperSelectionEvent) {
    this.selectedStateIndex = event.selectedIndex;
  }
}
