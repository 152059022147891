import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

import { ExecutiveFormGroup } from './types';

@Component({
  selector: 'app-create-work-order-executive-form',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  templateUrl: './create-work-order-executive-form.component.html',
  styleUrl: './create-work-order-executive-form.component.scss',
})
export class CreateWorkOrderExecutiveFormComponent {
  @Input({ required: true }) executiveFormGroup!: ExecutiveFormGroup;
}
