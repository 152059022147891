<section class="work-order-page">
  @if (!loading) {
  <mat-stepper
    class="work-order-stepper"
    [selectedIndex]="selectedStateIndex"
    (selectionChange)="stepSelected($event)"
    linear
  >
    @for (step of states; track $index) {
    <mat-step [completed]="step.status === 'done'" [state]="step.status">
      <ng-template matStepLabel>{{ step.label }}</ng-template>
      <ng-template matStepContent>
        <app-state-component-container
          [component]="states[$index].Component"
          [workOrder]="workOrder"
          (refresh)="updateWorkOrder()"
        />
      </ng-template>
    </mat-step>
    }

    <!-- Icon overrides. -->
    <ng-template matStepperIcon="pending">
      <mat-icon>radio_button_checked</mat-icon>
    </ng-template>
  </mat-stepper>
  } @else {
  <div class="loading"><mat-spinner></mat-spinner></div>
  }
</section>
