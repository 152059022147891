import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { environment } from '../../../environments/environment';

const LOGIN_URL = `${environment.api}/login`;

type ApiAuthResponse = {
  access_token: string | null;
  refresh_token: string | null;
};

type AuthResponse = {
  access: string | null;
  refresh: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public logIn(username: string, password: string): Observable<AuthResponse> {
    const body = {
      username,
      password,
    };

    return this.http.post<ApiAuthResponse>(LOGIN_URL, body).pipe(
      map((response) => {
        return {
          access: response.access_token || null,
          refresh: response.refresh_token || null,
        };
      })
    );
  }
}
