import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';

import { SubmitButtonComponent } from '../submit-button/submit-button.component';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    SubmitButtonComponent,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() message!: string;
  @Input() password!: string;
  @Input() username!: string;

  @Output() login = new EventEmitter();
  @Output() usernameChange = new EventEmitter();
  @Output() passwordChange = new EventEmitter();

  onUsernameChange(username: string) {
    this.usernameChange.emit(username);
  }

  onPasswordChange(password: string) {
    this.passwordChange.emit(password);
  }

  onSubmit() {
    this.login.emit({
      username: this.username,
      password: this.password,
    });
  }
}
