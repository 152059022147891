import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AsyncPipe, NgComponentOutlet } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { WorkOrder } from '../../domain/types/work-order';
import { WorkOrdersService } from '../../services/work-orders.service';
import { WorkOrderState } from '../../domain/types/work-order-states';
import { StateViewAbstractComponent } from '../state-view-component/state-view-abstract.component';

@Component({
  selector: 'app-state-component-container',
  standalone: true,
  imports: [AsyncPipe, MatProgressSpinnerModule, NgComponentOutlet],
  styleUrls: ['./state-component-container.component.scss'],
  templateUrl: './state-component-container.component.html',
})
export class StateComponentContainerComponent implements OnChanges, OnInit {
  @Input() workOrder!: WorkOrder;
  @Input() component!: NgComponentOutlet['ngComponentOutlet'];
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  loading = false;
  workOrderState?: WorkOrderState;

  updateWorkOrder = () => this.refresh.emit();
  updateWorkOrderState = () => this.onUpdateWorkOrderState();

  constructor(private workOrdersService: WorkOrdersService) {}

  ngOnInit() {
    this.onUpdateWorkOrderState();
  }

  ngOnChanges() {
    this.onUpdateWorkOrderState();
  }

  onUpdateWorkOrderState() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const workOrderStateId =
      /**
       * This is a TS hack as I couldn't find a way to get
       * the typing straight for the component property.
       */
      (this.component as unknown as StateViewAbstractComponent).STATE_ID;

    this.workOrdersService
      .getWorkOrderState(this.workOrder.externalId, workOrderStateId)
      .subscribe((workOrderState) => {
        this.workOrderState = workOrderState;
        this.loading = false;
      });
  }
}
