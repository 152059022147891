<div class="app-file-input">
  <button
    mat-raised-button
    class="file-input-button"
    (click)="fileInput.click()"
  >
    <span>Seleccionar documento</span>
    <input
      #fileInput
      type="file"
      style="display: none"
      [accept]="acceptedTypes"
      [multiple]="false"
      (change)="fileChanged($event)"
    />
  </button>
</div>
