<mat-expansion-panel class="app-app-appearing-list-form-item">
  <mat-expansion-panel-header>
    <app-appearing-badge
      [notaryAppearing]="notaryAppearingFormGroup.getRawValue()"
    />
  </mat-expansion-panel-header>

  <app-appearing-form [notaryAppearingFormGroup]="notaryAppearingFormGroup" />

  <mat-action-row>
    <button class="remove-button" mat-raised-button (click)="remove.emit()">
      Eliminar
    </button>
  </mat-action-row>
</mat-expansion-panel>
