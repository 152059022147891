import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { WorkOrderState } from '../../domain/types/work-order-states';

@Component({
  selector: 'app-base-state',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './base-state.component.html',
  styleUrl: './base-state.component.scss',
})
export class BaseStateComponent {
  @Input() workOrderState?: WorkOrderState;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
}
