import { inject } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Session } from '../services/auth/session';

type GenericHttpRequest = HttpRequest<unknown>;
type GenericHttpHandledEvent = Observable<HttpEvent<unknown>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericHttpEvent = HttpEvent<any>;

export class AuthInterceptor {
  session: Session;

  constructor(session: Session) {
    this.session = session;
  }

  intercept: HttpInterceptorFn = (request, next) => {
    const isLoginRequest = request.url.includes('/login');
    const isRefreshTokenRequest = request.url.includes('/refresh');

    if (isLoginRequest) {
      return next(request);
    }

    if (isRefreshTokenRequest) {
      return next(request).pipe(
        this.handle401Error(() => {
          return this.closeSessionAndThrow();
        })
      );
    }

    const requestWithBearer = this.getRequestWithBearer(request);

    if (!requestWithBearer) {
      return this.closeSessionAndThrow();
    }

    return next(requestWithBearer).pipe(
      this.handle401Error(() => {
        return this.refreshToken(request, next);
      })
    );
  };

  private handle401Error(
    onError: () => GenericHttpHandledEvent
  ): OperatorFunction<GenericHttpEvent, HttpEvent<unknown>> {
    return catchError<GenericHttpEvent, GenericHttpHandledEvent>((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return onError();
      }

      return throwError(() => error);
    });
  }

  private refreshToken(
    request: GenericHttpRequest,
    next: HttpHandlerFn
  ): GenericHttpHandledEvent {
    return this.session.refreshToken().pipe(
      switchMap((didTokenRefresh) => {
        if (!didTokenRefresh) {
          return this.closeSessionAndThrow();
        }

        const requestWithBearer = this.getRequestWithBearer(request);

        if (!requestWithBearer) {
          return this.closeSessionAndThrow();
        }

        return next(requestWithBearer);
      })
    );
  }

  private getRequestWithBearer(
    request: GenericHttpRequest
  ): GenericHttpRequest | null {
    const token = this.session.getAuthToken();

    if (!token) {
      return null;
    }

    return request.clone({
      setHeaders: {
        Authorization: token,
      },
    });
  }

  private closeSessionAndThrow(): Observable<never> {
    this.session.closeSession();

    return throwError(() => new Error('No autorizado'));
  }
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const session = inject(Session);
  const interceptor = new AuthInterceptor(session);

  return interceptor.intercept(req, next);
};
