import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';

import { environment } from '../environments/environment';

import { AUTH_HOME_ROUTE, UNAUTH_HOME_ROUTE, ROUTES } from './constants/routes';
import { HeaderComponent } from './components/header/header.component';
import { Session } from './services/auth/session';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent],
  template: `
    <div class="app-container">
      <app-header [navItems]="navItems" [show]="isLogged" (logout)="logout()" />
      <div class="page-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isLogged: boolean = false;
  navItems = [
    {
      name: 'Trabajos',
      path: `/${ROUTES.WORK_ORDERS_LIST}`,
    },
  ];

  constructor(private session: Session, private router: Router) {}

  ngOnInit() {
    console.info(`app version: ${environment.env} | ${environment.version}`);

    this.isLogged = Boolean(this.session.getUser());

    this.session.sessionChanged$.subscribe(({ user }) => {
      const redirectRoute = user ? AUTH_HOME_ROUTE : UNAUTH_HOME_ROUTE;
      this.isLogged = Boolean(user);
      this.router.navigate([redirectRoute]);
    });
  }

  logout() {
    this.session.closeSession();
  }
}
