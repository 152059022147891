import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import {
  NotaryFormModel,
  NotaryAppearing,
  NotaryPerson,
} from '../../../domain/types/notary';
import {
  NotaryForm,
  NotaryAppearingFormGroup,
  NotaryPersonFormGroup,
  NotaryFormDocument,
  NotaryDocumentGroup,
  NotaryUnsavedDocumentFormGroup,
} from '../types';
import { isDocumentUnsaved } from '../../../utils/file-upload/is-document-unsaved';

import { appearingListValidator, deedValidator } from './validators';
import { appearingValidator } from './validators/appearing-validator';

const createPersonFormGroup = (
  person?: NotaryPerson
): NotaryPersonFormGroup => {
  return new FormGroup(
    {
      checkDigit: new FormControl(person?.checkDigit, [Validators.required]),
      mail: new FormControl(person?.mail, [Validators.required]),
      fatherLastName: new FormControl(person?.fatherLastName),
      gender: new FormControl(person?.gender),
      motherLastName: new FormControl(person?.motherLastName),
      name: new FormControl(person?.name, [Validators.required]),
      rut: new FormControl(person?.rut, [Validators.required]),
    },
    [Validators.required]
  ) as NotaryPersonFormGroup;
};

export const createAppearingFormGroup = (
  appearingItem?: NotaryAppearing
): NotaryAppearingFormGroup => {
  return new FormGroup(
    {
      actuationType: new FormControl(appearingItem?.actuationType, [
        Validators.required,
      ]),
      notify: new FormControl(appearingItem?.notify || false, [
        Validators.required,
      ]),
      person: createPersonFormGroup(appearingItem?.person),
      personType: new FormControl(appearingItem?.personType, [
        Validators.required,
      ]),
      signatureStatus: new FormControl(
        appearingItem?.signatureStatus || false,
        [Validators.required]
      ),
    },
    [appearingValidator]
  ) as NotaryAppearingFormGroup;
};

export const createDocumentFormGroup = (
  document?: NotaryFormDocument
): NotaryDocumentGroup | NotaryUnsavedDocumentFormGroup => {
  if (document && isDocumentUnsaved(document)) {
    return new FormGroup({
      type: new FormControl(document?.type, [Validators.required]),
      file: new FormControl(document?.file, [Validators.required]),
      _file: new FormControl(document?._file, [Validators.required]),
    }) as NotaryUnsavedDocumentFormGroup;
  }

  return new FormGroup({
    type: new FormControl(document?.type, [Validators.required]),
    file: new FormControl(document?.file, [Validators.required]),
  }) as NotaryDocumentGroup;
};

export const createNotaryForm = (notaryForm?: NotaryFormModel): NotaryForm => {
  return new FormGroup({
    appearing: new FormArray<NotaryAppearingFormGroup>(
      (notaryForm?.appearing || []).map(createAppearingFormGroup),
      [Validators.required, appearingListValidator]
    ),
    documents: new FormArray<
      NotaryDocumentGroup | NotaryUnsavedDocumentFormGroup
    >((notaryForm?.documents || []).map(createDocumentFormGroup), [
      Validators.required,
      deedValidator,
    ]),
    formReadyToSent: new FormControl(notaryForm?.formReadyToSent),
    nature: new FormControl(notaryForm?.nature, [Validators.required]),
    observation: new FormControl(notaryForm?.observation),
  }) as NotaryForm;
};
