import { WorkOrderDocument } from '../../domain/types/work-order';
import { isDocumentUnsaved } from './is-document-unsaved';
import {
  ModelWithDocuments,
  ModelWithFormDocuments,
  ModelWithPlaceholderDocumentsAndFiles,
  WorkOrderDocumentOrPlaceholder,
  WorkOrderDocumentPlaceholder,
} from './types';

export const generatePlaceholderFiles = <
  Model extends ModelWithDocuments<W>,
  W extends WorkOrderDocument
>(
  formModel: ModelWithFormDocuments<Model, W>
): ModelWithPlaceholderDocumentsAndFiles<Model, W> => {
  const documents: WorkOrderDocumentOrPlaceholder<W>[] = [];
  const files: File[] = [];

  formModel.documents.forEach((document) => {
    if (isDocumentUnsaved(document)) {
      const placeholder = {
        type: document.type,
        file: document.file.name,
      } as WorkOrderDocumentPlaceholder<W>;

      documents.push(placeholder);
      files.push(document._file);
    } else {
      documents.push(document as W);
    }
  });

  return {
    model: {
      ...formModel,
      documents,
    },
    files,
  };
};
