import { Injectable } from '@angular/core';

import { User } from './types';

const USER_KEY = 'mortgage-workflow-user';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private user: User | null = null;

  constructor() {
    const stringifiedUser = localStorage.getItem(USER_KEY);

    if (stringifiedUser) {
      this.user = JSON.parse(stringifiedUser);
    }
  }

  public getUser(): User | null {
    return this.user;
  }

  public setUser(user: User | null): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.user = user;
  }
}
