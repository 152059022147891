import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '../../environments/environment';

import {
  LeafValue as BackendResponseDict,
  snakeToCamelKeys,
} from './utils/snake-camel';
import { Customer } from '../domain/types/customer';

const CUSTOMERS_URL = `${environment.api}/customers`;

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private http: HttpClient) {}

  public getCustomers(): Observable<Customer[]> {
    return this.http.get<BackendResponseDict[]>(CUSTOMERS_URL).pipe(
      map((response) => {
        return response.map(snakeToCamelKeys) as Customer[];
      })
    );
  }
}
