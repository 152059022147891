import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-notary-form-validation',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './notary-form-validation.component.html',
  styleUrl: './notary-form-validation.component.scss',
})
export class NotaryFormValidationComponent {
  @Input() errors: string[] = [];
}
