import { Component, Input } from '@angular/core';

import { DocumentsListItemComponent } from './documents-list-item/documents-list-item.component';
import { WorkOrderDocument } from '../../domain/types/work-order';

@Component({
  selector: 'app-documents-list',
  standalone: true,
  imports: [DocumentsListItemComponent],
  templateUrl: './documents-list.component.html',
  styleUrl: './documents-list.component.scss',
})
export class DocumentsListComponent {
  @Input() documents!: WorkOrderDocument[];
}
