<div class="app-notary-document-form">
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Tipo</mat-label>
    <mat-select [formControl]="documentFormGroup.controls.type">
      @for (option of documentTypesOptions; track $index) {
      <mat-option value="{{ option.value }}"> {{ option.label }} </mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (documentFormGroup.controls._file.value) {
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Nombre</mat-label>
    <input
      matInput
      [readonly]="true"
      [value]="documentFormGroup.controls._file.value.name"
    />
  </mat-form-field>
  }

  <app-file-input (selectedFilesChanged)="onFileSelected($event)" />
</div>
