<div class="app-notary-documents-list-form">
  <h3 class="title">Documentos</h3>
  <div class="content">
    @for (document of documents; track $index) {
    <app-notary-documents-list-form-item
      [document]="document"
      (remove)="removeDocument($index)"
    />
    }
  </div>
  <button
    class="add-button"
    mat-raised-button
    (click)="openAddNotaryDocumentDialog()"
  >
    Agregar documento
  </button>
</div>
