import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkOrderDocument } from '../../domain/types/work-order';
import { WorkOrderDocumentFormGroup, WorkOrderUnsavedDocument } from './types';

export const createDocumentFormGroup = <W extends WorkOrderDocument>(
  document?: WorkOrderUnsavedDocument<W>
): WorkOrderDocumentFormGroup<W> => {
  return new FormGroup({
    type: new FormControl(document?.type, [Validators.required]),
    file: new FormControl(document?.file, [Validators.required]),
    _file: new FormControl(document?._file),
  }) as WorkOrderDocumentFormGroup<W>;
};
