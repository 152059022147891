@if (notaryForm) {
<form class="app-notary-form" [formGroup]="notaryForm">
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Naturaleza</mat-label>
    <mat-select [formControl]="notaryForm.controls.nature">
      @for (option of natureOptions; track $index) {
      <mat-option value="{{ option.value }}"> {{ option.label }} </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Observaciones</mat-label>
    <input matInput [formControl]="notaryForm.controls.observation" />
  </mat-form-field>

  <app-appearing-list-form
    class="form-field"
    [notaryAppearingFormArray]="notaryForm.controls.appearing"
  />

  <app-notary-documents-list-form
    class="form-field"
    [documents]="notaryForm.controls.documents.getRawValue()"
    (documentsChange)="onDocumentsChange($event)"
  />
</form>
}
