<div class="app-notary-form-validation">
  <ul class="validation-list">
    @for (error of errors; track $index) {
    <li>
      <mat-icon class="cross">cancel</mat-icon>
      <span>{{ error }}</span>
    </li>
    }
  </ul>
</div>
