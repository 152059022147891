<h1 mat-dialog-title>Crear Orden de Trabajo</h1>
<div mat-dialog-content>
  <form>
    <mat-form-field class="example-full-width">
      <mat-label>ID Externo</mat-label>
      <input matInput [formControl]="form.controls.externalId" />
    </mat-form-field>
  </form>
  <p>{{ errorMessage }}</p>
</div>
<div mat-dialog-actions>
  <app-submit-button
    color="secondary"
    type="button"
    [loading]="loading"
    [disabled]="form.invalid"
    (buttonClick)="create()"
  >
    Crear
  </app-submit-button>
</div>
