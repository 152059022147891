import { Component } from '@angular/core';

import { BaseStateComponent } from '../../../../components/base-state/base-state.component';
import { StateViewAbstractComponent } from '../../../../components/state-view-component/state-view-abstract.component';
import { WORK_ORDER_STATE } from '../../../../domain/constants/work-order-states';
import { WorkOrderCompletedStateData } from '../../../../domain/types/work-order-states';

@Component({
  selector: 'app-work-order-completed',
  standalone: true,
  imports: [BaseStateComponent],
  templateUrl: './work-order-completed.component.html',
  styleUrl: './work-order-completed.component.scss',
})
export class WorkOrderCompletedComponent extends StateViewAbstractComponent<WorkOrderCompletedStateData> {
  static STATE_ID = WORK_ORDER_STATE.WORK_ORDER_COMPLETED;
}
