import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { NATURE, NATURE_LABEL } from '../../domain/constants/notary';

@Component({
  selector: 'app-nature-select',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './nature-select.component.html',
  styleUrl: './nature-select.component.scss',
})
export class NatureSelectComponent {
  @Input({ required: true }) natureFormControl!: FormControl<string>;

  natureOptions = Object.keys(NATURE_LABEL)
    .map((key) => ({
      value: key,
      label: NATURE_LABEL[key as NATURE],
    }))
    .sort((option0, option1) => {
      if (option0.label < option1.label) {
        return -1;
      }
      if (option0.label > option1.label) {
        return 1;
      }
      return 0;
    });
}
