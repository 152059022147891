import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';

import { createDocumentFormGroup } from '../../../../utils/file-upload/create-document-form-group';
import { NotaryDocument } from '../../../../domain/types/notary';
import { formToDocument } from '../../../../utils/file-upload/form-to-document';
import { NotaryDocumentFormComponent } from '../notary-document-form/notary-document-form.component';
import { WorkOrderUnsavedDocument } from '../../../../utils/file-upload/types';

@Component({
  selector: 'app-add-notary-document-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    NotaryDocumentFormComponent,
  ],
  templateUrl: './add-notary-document-dialog.component.html',
  styleUrl: './add-notary-document-dialog.component.scss',
})
export class AddNotaryDocumentDialogComponent {
  newNotaryDocumentFormGroup = createDocumentFormGroup<NotaryDocument>();

  get newDocument(): WorkOrderUnsavedDocument<NotaryDocument> | undefined {
    return formToDocument(this.newNotaryDocumentFormGroup);
  }
}
