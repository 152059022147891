import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { CommonModule } from '@angular/common';

export type ButtonColor = 'primary' | 'secondary' | 'warning' | 'danger';
export type ButtonType = 'button' | 'submit' | 'reset';

@Component({
  selector: 'app-submit-button',
  standalone: true,
  imports: [MatButtonModule, MatProgressSpinnerModule, CommonModule],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss',
})
export class SubmitButtonComponent {
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() loading: boolean = false;
  @Input() type: ButtonType = 'button';
  /**
   * TODO: We need to standarize how are we going to call our app colors
   * and sync them with global variables.
   * This map should be moved to a global utility with well defined ENUMS
   * and a function to get the color based on the ENUM.
   * Ticket: https://equipolegalbot.atlassian.net/browse/LB-1221
   */
  @Input() set color(color: ButtonColor) {
    switch (color) {
      case 'primary':
        this.matColor = 'primary';
        break;
      case 'secondary':
        this.matColor = 'accent';
        break;
      case 'warning':
        this.matColor = 'warn';
        break;
      case 'danger':
        this.matColor = 'warn';
        break;
    }
  }

  @Output() buttonClick = new EventEmitter();

  matColor: ThemePalette;

  clickHandler() {
    this.buttonClick.emit();
  }
}
