<div class="app-base-state">
  <div>
    <mat-icon color="warn">info</mat-icon>
    Esta vista no está implementada aún. Por mientras te dejamos el objeto del
    estado en JSON
  </div>
  <pre class="raw-state-data">{{ workOrderState | json }}</pre>
  <button mat-raised-button color="accent" (click)="refresh.emit()">
    Actualizar
  </button>
</div>
