import { Component, Input } from '@angular/core';
import { WorkOrder } from '../../domain/types/work-order';
import {
  WorkOrderState,
  WorkOrderStateData,
} from '../../domain/types/work-order-states';
import { WORK_ORDER_STATE } from '../../domain/constants/work-order-states';

@Component({
  selector: 'app-state-view-component-interface',
  standalone: true,
  imports: [],
  template: '',
})
export class StateViewAbstractComponent<StateData = WorkOrderStateData> {
  STATE_ID!: WORK_ORDER_STATE;

  /**
   * As we are dynamically loading the state components with
   * ngComponentOutlet, we need to pass these functions as inputs
   * instead of using an output event emitter.
   */
  @Input() updateWorkOrder!: () => void;
  @Input() updateWorkOrderState!: () => void;
  @Input() workOrder!: WorkOrder;
  @Input() workOrderState!: WorkOrderState<StateData>;
}
