<section class="work-orders-page">
  <div class="actions-container">
    <app-create-work-order-button (created)="onWorkOrderCreated($event)" />
  </div>

  <table mat-table [dataSource]="workOrders" class="mat-elevation-z8">
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt }}</td>
    </ng-container>

    <ng-container matColumnDef="documentId">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.externalId }}</td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">{{ element.state }}</td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef>Editado</th>
      <td mat-cell *matCellDef="let element">{{ element.updatedAt }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="navigateToWorkOder(row)"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</section>
