import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';

import { AddNotaryDocumentDialogComponent } from '../add-notary-document-dialog/add-notary-document-dialog.component';
import { NotaryFormDocument } from '../../types';
import { NotaryDocumentsListItemComponent } from '../notary-documents-list-item/notary-documents-list-item.component';

@Component({
  selector: 'app-notary-documents-list-form',
  standalone: true,
  imports: [MatButtonModule, NotaryDocumentsListItemComponent],
  templateUrl: './notary-documents-list.component.html',
  styleUrl: './notary-documents-list.component.scss',
})
export class NotaryDocumentsListComponent {
  @Input() documents!: NotaryFormDocument[];
  @Output() documentsChange: EventEmitter<NotaryFormDocument[]> =
    new EventEmitter();

  constructor(public dialog: MatDialog) {}

  removeDocument(index: number) {
    this.documentsChange.emit(this.documents.filter((_, i) => i !== index));
  }

  openAddNotaryDocumentDialog() {
    const dialogRef = this.dialog.open(AddNotaryDocumentDialogComponent);

    dialogRef.afterClosed().subscribe((document: NotaryFormDocument) => {
      if (document) {
        this.documentsChange.emit([...this.documents, document]);
      }
    });
  }
}
