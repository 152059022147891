import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '../../environments/environment';
import { WorkOrder, WorkOrderDocument } from '../domain/types/work-order';
import {
  CreateWorkOrderStateData,
  WorkOrderState,
} from '../domain/types/work-order-states';
import {
  ModelWithDocuments,
  ModelWithPlaceholderDocuments,
} from '../utils/file-upload/types';

import {
  LeafValue as BackendResponseDict,
  camelToSnakeKeys,
  snakeToCamelKeys,
} from './utils/snake-camel';
import { WORK_ORDER_STATE } from '../domain/constants/work-order-states';

const WORK_ORDERS_URL = `${environment.api}/work-orders`;

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersService {
  constructor(private http: HttpClient) {}

  public getWorkOrders(): Observable<WorkOrder[]> {
    return this.http.get<BackendResponseDict[]>(WORK_ORDERS_URL).pipe(
      map((response) => {
        return response.map(snakeToCamelKeys) as WorkOrder[];
      })
    );
  }

  public getWorkOrderById(id: string): Observable<WorkOrder> {
    return this.http.get<BackendResponseDict>(`${WORK_ORDERS_URL}/${id}`).pipe(
      map((response) => {
        return snakeToCamelKeys(response) as WorkOrder;
      })
    );
  }

  public getWorkOrderState(
    workOrderId: string,
    state?: WORK_ORDER_STATE
  ): Observable<WorkOrderState> {
    const baseUrl = `${WORK_ORDERS_URL}/${workOrderId}/state`;
    const url = state ? `${baseUrl}/${state}` : baseUrl;

    return this.http.get<BackendResponseDict>(url).pipe(
      map((response) => {
        return snakeToCamelKeys(response) as WorkOrderState;
      })
    );
  }

  public createWorkOrder(workOrder: CreateWorkOrderStateData) {
    const body = camelToSnakeKeys({ ...workOrder });

    return this.http.post<BackendResponseDict>(WORK_ORDERS_URL, body).pipe(
      map((response) => {
        return snakeToCamelKeys(response) as WorkOrder;
      })
    );
  }

  public updateStateWithFiles<
    Model extends ModelWithDocuments<W>,
    W extends WorkOrderDocument,
    StateData
  >(
    workOrderId: string,
    stateData: ModelWithPlaceholderDocuments<Model, W>,
    files: File[]
  ): Observable<WorkOrderState<StateData>> {
    const formData = new FormData();
    const stringifiedData = JSON.stringify(camelToSnakeKeys(stateData));

    formData.append('json', stringifiedData);

    files.forEach((file) => {
      formData.append(file.name, file);
    });

    return this.http
      .post<BackendResponseDict>(
        `${WORK_ORDERS_URL}/${workOrderId}/state`,
        formData
      )
      .pipe(
        map((response) => {
          return snakeToCamelKeys(response) as WorkOrderState<StateData>;
        })
      );
  }

  public downloadDocument(name: string, url: string): void {
    this.http
      .get(`${environment.api}${url}`, { responseType: 'blob' })
      .subscribe(async (response: Blob) => {
        const type = response.type;
        const base64 = await response.text();
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:${type};base64,${base64}`;
        downloadLink.setAttribute('download', name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode?.removeChild(downloadLink);
      });
  }

  public updateOnDemand(
    externalIdWorkflow: string
  ): Observable<WorkOrderState> {
    return this.http
      .post<BackendResponseDict>(
        `${WORK_ORDERS_URL}/${externalIdWorkflow}/update-on-demand`,
        null
      )
      .pipe(
        map((response) => {
          return snakeToCamelKeys(response) as WorkOrderState;
        })
      );
  }
}
