import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DOCUMENT_TYPE } from '../../../../domain/constants/notary';
import { isDocumentUnsaved } from '../../../../utils/file-upload/is-document-unsaved';
import { NotaryFormDocument } from '../../types';

export const deedValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const documents = control.getRawValue();

  const deeds = documents.filter(
    (document: NotaryFormDocument) => document.type === DOCUMENT_TYPE.DEED
  );

  const errors = [];

  if (deeds.length === 0) {
    errors.push({
      key: 'noDeed',
      value: true,
      message: 'Debe haber al menos una escritura',
    });
  } else if (deeds.length > 1) {
    errors.push({
      key: 'multipleDeeds',
      value: true,
      message: 'Debe haber solo una escritura',
    });
  }

  deeds.forEach((deed: NotaryFormDocument) => {
    const isUnsaved = isDocumentUnsaved(deed);
    const deedFile = isUnsaved ? deed._file : deed.file;

    if (deedFile?.name && !deedFile.name.endsWith('.docx')) {
      errors.push({
        key: 'deedIsNotDocx',
        value: true,
        message: 'El archivo debe ser un documento de Word',
      });
    }
  });

  if (!errors.length) {
    return null;
  }

  return errors.reduce((acc, { key, value, message }) => {
    if (value) {
      return { ...acc, [key]: message };
    }

    return acc;
  }, {});
};
