import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatToolbarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input()
  navItems: {
    name: string;
    path: string;
  }[] = [];
  @Input() show: boolean = true;

  @Output() logout = new EventEmitter<void>();

  logoutHandler() {
    this.logout.emit();
  }
}
