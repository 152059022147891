<button
  mat-raised-button
  class="app-submit-button"
  [ngClass]="{
    'app-submit-button--full-width': fullWidth
  }"
  [color]="matColor"
  [disabled]="disabled || loading"
  [type]="type"
  (click)="clickHandler()"
>
  @if (loading) {<mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>}
  @else {<ng-content *ngIf="!loading"></ng-content>}
</button>
