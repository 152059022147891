import { Directive, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatRut } from '../../utils/format-rut';

@Directive({
  selector: '[appRutMask]',
  standalone: true,
})
export class RutMaskDirective implements OnInit {
  @Input() formControl!: FormControl;

  ngOnInit() {
    this.formControl.valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.setValue(formatRut(value), {
          emitEvent: false,
        });
      }
    });
  }
}
