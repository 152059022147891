<div class="app-create-work-order-dialog">
  <h1 mat-dialog-title>Crear Orden de Trabajo</h1>
  <div mat-dialog-content>
    <form [formGroup]="workOrderFormGroup" class="form">
      <div class="form-row">
        <app-customer-select
          [customerFormControl]="workOrderFormGroup.controls.customerId"
        />
        <app-nature-select
          [natureFormControl]="workOrderFormGroup.controls.nature"
        />
      </div>
      <app-create-work-order-buyer-form
        [buyerFormGroup]="workOrderFormGroup.controls.buyerData"
      />
      <app-create-work-order-executive-form
        [executiveFormGroup]="workOrderFormGroup.controls.executive"
      />
    </form>
    <p>{{ errorMessage }}</p>
  </div>
  <div mat-dialog-actions>
    <app-submit-button
      color="secondary"
      type="button"
      [loading]="loading"
      [disabled]="workOrderFormGroup.invalid"
      (buttonClick)="create()"
    >
      Crear
    </app-submit-button>
  </div>
</div>
