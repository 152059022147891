import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

import {
  ACTUATION_TYPE,
  PERSON_TYPE,
  GENDER,
} from '../../../../domain/constants/notary';
import { AppearingBadgeComponent } from '../appearing-badge/appearing-badge.component';
import { AppearingFormComponent } from '../appearing-form/appearing-form.component';
import { createAppearingFormGroup } from '../../utils/forms';
import { NotaryAppearing } from '../../../../domain/types/notary';
import { NotaryAppearingFormGroup } from '../../types';

const SAVED_APPEARING_ITEMS: NotaryAppearing[] = [
  {
    actuationType: ACTUATION_TYPE.REPRESENTATIVE,
    notify: false,
    person: {
      checkDigit: '9',
      mail: 'cagigoux@legalbot.cl',
      fatherLastName: 'Gigoux',
      gender: GENDER.MALE,
      motherLastName: 'López',
      name: 'Cristóbal Alejandro',
      rut: 15666127,
    },
    personType: PERSON_TYPE.CHILEAN,
    signatureStatus: true,
  },
  {
    actuationType: ACTUATION_TYPE.REPRESENTATIVE,
    notify: false,
    person: {
      checkDigit: 'K',
      mail: 'jcastellon@legalbot.cl',
      fatherLastName: 'Castellón',
      gender: GENDER.MALE,
      motherLastName: 'Lavín',
      name: 'Juan Luis',
      rut: 15715883,
    },
    personType: PERSON_TYPE.CHILEAN,
    signatureStatus: true,
  },
  {
    actuationType: ACTUATION_TYPE.REPRESENTATIVE,
    notify: false,
    person: {
      checkDigit: '8',
      mail: 'jgacitua@legalbot.cl',
      fatherLastName: 'Gacitúa',
      gender: GENDER.MALE,
      motherLastName: 'Carafi',
      name: 'Jaime Andrés',
      rut: 15644573,
    },
    personType: PERSON_TYPE.CHILEAN,
    signatureStatus: true,
  },
  {
    actuationType: ACTUATION_TYPE.APPLICANT,
    notify: false,
    person: {
      checkDigit: 'K',
      mail: 'cagigoux@legalbot.cl',
      fatherLastName: null,
      gender: null,
      motherLastName: null,
      name: 'BANCO SANTANDER CHILE',
      rut: 97036000,
    },
    personType: PERSON_TYPE.LEGAL_ENTITY,
    signatureStatus: false,
  },
  {
    actuationType: ACTUATION_TYPE.RAISER,
    notify: false,
    person: {
      checkDigit: 'K',
      mail: 'cagigoux@legalbot.cl',
      fatherLastName: null,
      gender: null,
      motherLastName: null,
      name: 'BANCO SANTANDER CHILE',
      rut: 97036000,
    },
    personType: PERSON_TYPE.LEGAL_ENTITY,
    signatureStatus: false,
  },
];

@Component({
  selector: 'app-add-appearing-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatTabsModule,
    AppearingBadgeComponent,
    AppearingFormComponent,
  ],
  templateUrl: './add-appearing-dialog.component.html',
  styleUrl: './add-appearing-dialog.component.scss',
})
export class AddAppearingDialogComponent {
  newNotaryAppearingFormGroup = createAppearingFormGroup();
  savedAppearingItems: NotaryAppearing[] = SAVED_APPEARING_ITEMS;

  appearingToFormGroup(
    notaryAppearing: NotaryAppearing
  ): NotaryAppearingFormGroup {
    return createAppearingFormGroup(notaryAppearing);
  }
}
