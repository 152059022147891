import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { WorkOrdersService } from '../../services/work-orders.service';
import { SubmitButtonComponent } from '../submit-button/submit-button.component';

export interface DialogData {
  loading: boolean;
}

@Component({
  selector: 'app-create-work-order-dialog',
  templateUrl: './create-work-order-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    SubmitButtonComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class CreateWorkOrderDialogComponent {
  loading = false;
  errorMessage = '';

  form = new FormGroup({
    externalId: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateWorkOrderDialogComponent>,
    private workOrdersService: WorkOrdersService
  ) {}

  create() {
    const externalId = this.form.value.externalId || '';

    this.errorMessage = '';
    this.loading = true;

    this.workOrdersService.createWorkOrder(externalId).subscribe({
      next: (workOrder) => {
        this.dialogRef.close(workOrder);
      },
      error: () => {
        this.loading = false;
        this.errorMessage = 'Error creando orden de trabajo';
      },
    });
  }
}
