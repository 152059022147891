import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import {
  ACTUATION_TYPE,
  PERSON_TYPE,
} from '../../../../domain/constants/notary';
import { NotaryAppearing } from '../../../../domain/types/notary';

export const appearingListValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const apearingList = control.getRawValue();

  const appearingTypes = apearingList.reduce(
    (
      acc: Record<ACTUATION_TYPE | PERSON_TYPE, number>,
      item: NotaryAppearing
    ) => {
      const { personType, actuationType } = item;

      if (!acc[personType]) {
        acc[personType] = 0;
      }

      if (!acc[actuationType]) {
        acc[actuationType] = 0;
      }

      acc[personType]++;
      acc[actuationType]++;

      return acc;
    },
    {}
  );

  const errors = [
    {
      key: 'noRepresentative',
      value: !appearingTypes[ACTUATION_TYPE.REPRESENTATIVE],
      message: 'Debe haber al menos un representante',
    },
    {
      key: 'multipleRepresentative',
      value: appearingTypes[ACTUATION_TYPE.REPRESENTATIVE] > 1,
      message: 'Debe haber solo un representante',
    },
    {
      key: 'noApplicant',
      value: !appearingTypes[ACTUATION_TYPE.APPLICANT],
      message: 'Debe haber al menos un requirente',
    },
  ].filter(({ value }) => value);

  if (!errors.length) {
    return null;
  }

  return errors.reduce((acc, { key, value, message }) => {
    if (value) {
      return { ...acc, [key]: message };
    }

    return acc;
  }, {});
};
