<div class="app-notary-working-on-request app-grid info-container">
  <div class="app-column">
    <mat-card class="app-card">
      <mat-card-content
        ><h2>{{ state?.externalIdWorkflow }}</h2>
        <section class="cover-container">
          <h3>Carátula</h3>
          <mat-list>
            <mat-list-item>
              <span matListItemTitle>{{ state?.cover }}</span>
              <span matListItemLine>{{ state?.creationDate }}</span>
            </mat-list-item>
          </mat-list>
        </section>
        <section class="appearings-container">
          <h3>Comparecientes</h3>
          <mat-list>
            @for (appearing of state?.appearing; track $index) {
            <mat-list-item>
              <span matListItemTitle
                >{{ appearing.person.name }}
                {{ appearing.person.fatherLastName }}</span
              >
              <span matListItemLine>{{ appearing.signatureStatus }}</span>
            </mat-list-item>
            }
          </mat-list>
        </section>
        <section class="documents-container">
          <h3>Documentos</h3>
          <div class="content">
            <app-documents-list [documents]="state?.documents || []" />
          </div>
        </section>
        <app-submit-button [loading]="updating" (buttonClick)="onUpdate()"
          >Actualizar Estado</app-submit-button
        >
      </mat-card-content>
    </mat-card>
  </div>
  <div class="app-column">
    <mat-card class="app-card">
      <mat-card-content>
        <section class="status-history-container">
          <h3>Estados</h3>
          <app-status-history [statuses]="state?.status || []" />
        </section>
      </mat-card-content>
    </mat-card>
  </div>
</div>
