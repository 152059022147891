import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { WorkOrder } from '../../domain/types/work-order';

import { CreateWorkOrderDialogComponent } from './create-work-order-dialog.component';

@Component({
  selector: 'app-create-work-order-button',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  template: `
    <button mat-raised-button (click)="openDialog()">
      Nueva Orden de Trabajo
    </button>
  `,
})
export class CreateWorkOrderButtonComponent {
  @Output() created: EventEmitter<WorkOrder> = new EventEmitter();

  Dialog = CreateWorkOrderDialogComponent;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(this.Dialog);

    dialogRef.afterClosed().subscribe((workOrder: WorkOrder) => {
      if (workOrder) {
        this.created.emit(workOrder);
      }
    });
  }
}
