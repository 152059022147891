import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { AppearingListFormComponent } from './appearing/appearing-list-form/appearing-list-form.component';
import { createDocumentFormGroup, createNotaryForm } from './utils/forms';
import { generatePlaceholderFiles } from '../../utils/file-upload/generate-placeholder-files';
import { NATURE, NATURE_LABEL } from '../../domain/constants/notary';
import { NotaryDocumentsListComponent } from './documents/notary-documents-list/notary-documents-list.component';
import { NotaryForm, NotaryFormChanges, NotaryFormDocument } from './types';
import { NotaryFormModel, NotaryDocument } from '../../domain/types/notary';
import { getFormErrors } from './utils/get-form-errors';

@Component({
  selector: 'app-notary-form',
  standalone: true,
  imports: [
    AppearingListFormComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NotaryDocumentsListComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './notary-form.component.html',
  styleUrl: './notary-form.component.scss',
})
export class NotaryFormComponent implements OnChanges {
  @Input() notaryFormModel?: NotaryFormModel;
  @Input() saving = false;

  @Output()
  formChange: EventEmitter<NotaryFormChanges> = new EventEmitter();

  notaryForm?: NotaryForm;

  natureOptions = Object.keys(NATURE_LABEL)
    .map((key) => ({
      value: key,
      label: NATURE_LABEL[key as NATURE],
    }))
    .sort((option0, option1) => {
      if (option0.label < option1.label) {
        return -1;
      }
      if (option0.label > option1.label) {
        return 1;
      }
      return 0;
    });

  ngOnChanges(changes: SimpleChanges): void {
    const { notaryFormModel } = changes;
    const newNotaryFormModel = notaryFormModel?.currentValue;
    const oldNotaryFormModel = notaryFormModel?.previousValue;

    if (!newNotaryFormModel || newNotaryFormModel === oldNotaryFormModel) {
      return;
    }

    this.notaryForm = createNotaryForm(newNotaryFormModel);

    this.onFormChange();
    this.notaryForm.valueChanges.subscribe(() => {
      this.onFormChange();
    });
  }

  onDocumentsChange(documents: NotaryFormDocument[]) {
    if (!this.notaryForm) {
      return;
    }

    const documentsControl = this.notaryForm.controls.documents;
    documentsControl.clear({ emitEvent: false });
    documents.forEach((document) => {
      documentsControl.push(createDocumentFormGroup(document), {
        emitEvent: false,
      });
    });
    documentsControl.markAsDirty();
    documentsControl.updateValueAndValidity();
  }

  onFormChange() {
    if (!this.notaryForm) {
      return;
    }

    const rawValue = this.notaryForm.getRawValue();

    const modelAndFiles = generatePlaceholderFiles<
      NotaryFormModel,
      NotaryDocument
    >(rawValue);

    this.formChange.emit({
      ...modelAndFiles,
      form: {
        errors: getFormErrors(this.notaryForm),
        dirty: this.notaryForm.dirty,
        valid: this.notaryForm.valid,
      },
    });
  }
}
