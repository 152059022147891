import { Component } from '@angular/core';

import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { Session } from '../../services/auth/session';

@Component({
  standalone: true,
  imports: [LoginFormComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loading: boolean = false;
  message: string = '';
  password: string = '';
  username: string = '';

  constructor(private session: Session) {}

  onSubmit() {
    if (this.loading) {
      return;
    }

    this.message = '';
    this.loading = true;

    this.session.logIn(this.username, this.password).subscribe({
      error: () => {
        this.loading = false;
        this.message = 'Tu correo no coincide con tu clave';
      },
    });
  }
}
