import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import {
  ACTUATION_TYPE_LABEL,
  ACTUATION_TYPE,
  PERSON_TYPE_LABEL,
  PERSON_TYPE,
  GENDER_LABEL,
  GENDER,
} from '../../../../domain/constants/notary';
import { joinRut, splitRut } from '../../utils/format-rut';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotaryAppearingFormGroup } from '../../types';
import { RutMaskDirective } from '../../directives/rut-mask.directive';
import { rutValidator } from '../../utils/validators';

@Component({
  selector: 'app-appearing-form',
  standalone: true,
  imports: [
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RutMaskDirective,
  ],
  templateUrl: './appearing-form.component.html',
  styleUrl: './appearing-form.component.scss',
})
export class AppearingFormComponent implements OnInit {
  @Input() notaryAppearingFormGroup!: NotaryAppearingFormGroup;

  _rut: FormControl = new FormControl('', [Validators.required, rutValidator]);

  ngOnInit(): void {
    const { rut, checkDigit } =
      this.notaryAppearingFormGroup.controls.person.value;

    this._rut.setValue(joinRut(rut, checkDigit));

    this._rut.valueChanges.subscribe((value) => {
      const { rutDigits, checkDigit } = splitRut(value);

      this.notaryAppearingFormGroup.controls.person.patchValue({
        rut: rutDigits,
        checkDigit,
      });
    });
  }

  actuationTypesOptions = Object.keys(ACTUATION_TYPE_LABEL).map((key) => ({
    value: key,
    label: ACTUATION_TYPE_LABEL[key as ACTUATION_TYPE],
  }));

  personTypeOptions = Object.keys(PERSON_TYPE_LABEL).map((key) => ({
    value: key,
    label: PERSON_TYPE_LABEL[key as PERSON_TYPE],
  }));

  genderOptions = Object.keys(GENDER_LABEL).map((key) => ({
    value: key,
    label: GENDER_LABEL[key as GENDER],
  }));

  get isActuatorTypeLegalEntity(): boolean {
    return (
      this.notaryAppearingFormGroup.controls.personType.value ===
      PERSON_TYPE.LEGAL_ENTITY
    );
  }
}
