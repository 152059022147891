<div class="app-status-history">
  <mat-stepper orientation="vertical">
    @for (status of statuses; track $index) {
    <mat-step [completed]="$index" state="pending">
      <ng-template matStepLabel>
        <div class="step-label">
          <span
            >{{ status.status.name }}@if (status.observation) {<br />{{
              status.observation
            }}}</span
          >
          <span>{{ status.date }}</span>
        </div>
      </ng-template>
    </mat-step>
    }
  </mat-stepper>
</div>
