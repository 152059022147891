import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';

import { NotaryWorkOrderStatus } from '../../domain/types/notary';

@Component({
  selector: 'app-status-history',
  standalone: true,
  imports: [CommonModule, MatStepperModule],
  templateUrl: './status-history.component.html',
  styleUrl: './status-history.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class StatusHistoryComponent {
  @Input() statuses!: NotaryWorkOrderStatus[];
}
