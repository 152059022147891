<div class="app-add-appearing-dialog">
  <h1 mat-dialog-title>Agregar compareciente</h1>
  <div mat-dialog-content class="content">
    <mat-tab-group>
      <mat-tab label="Nuevo">
        <div class="tab-content">
          <app-appearing-form
            [notaryAppearingFormGroup]="newNotaryAppearingFormGroup"
          />
        </div>
      </mat-tab>
      <mat-tab label="Guardados">
        <div class="tab-content">
          <div class="saved-appearing-list">
            @for (notaryAppearing of savedAppearingItems; track $index) {
            <button
              class="saved-appearing-button"
              mat-raised-button
              [mat-dialog-close]="appearingToFormGroup(notaryAppearing)"
            >
              <app-appearing-badge [notaryAppearing]="notaryAppearing" />
            </button>
            }
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="">Cancelar</button>
    <button mat-button [mat-dialog-close]="newNotaryAppearingFormGroup">
      Agregar
    </button>
  </div>
</div>
