import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import {
  ACTUATION_TYPE_LABEL,
  PERSON_TYPE,
} from '../../../../domain/constants/notary';
import { NotaryAppearing } from '../../../../domain/types/notary';

const APPEARING_ICON = {
  LEGAL_ENTITY: 'account_balance',
  INDIVIDUAL: 'account_circle',
};

@Component({
  selector: 'app-appearing-badge',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './appearing-badge.component.html',
  styleUrl: './appearing-badge.component.scss',
})
export class AppearingBadgeComponent {
  @Input() notaryAppearing!: Partial<NotaryAppearing>;

  get icon(): string {
    if (this.notaryAppearing.personType === PERSON_TYPE.LEGAL_ENTITY) {
      return APPEARING_ICON.LEGAL_ENTITY;
    }

    return APPEARING_ICON.INDIVIDUAL;
  }

  get actuationTypeLabel(): string {
    if (!this.notaryAppearing.actuationType) {
      return '';
    }

    return ACTUATION_TYPE_LABEL[this.notaryAppearing.actuationType];
  }
}
