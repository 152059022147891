@if (show) {
<header class="app-header" [class.mat-elevation-z2]="true">
  <mat-toolbar class="toolbar" color="primary">
    <img
      class="logo"
      src="assets/images/legalbot-logo-h-pink.png"
      alt="legalbot-logo"
    />

    @for (navItem of navItems; track $index) {
    <a
      mat-button
      class="nav-button"
      routerLinkActive="active"
      [routerLink]="navItem.path"
    >
      {{ navItem.name }}
    </a>
    }

    <span class="spacer"></span>

    <button mat-button class="nav-button" (click)="logoutHandler()">
      Salir
    </button>
  </mat-toolbar>
</header>
}
